.blog__head {    
    padding-bottom: 35px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    margin: 0px 06vw;
}

.blog__body {
    background-color: #f9f9f9;
    padding: 100px 10vw;
}

.blog__body__tit {
    font-size: 40px;
    font-weight: 500;
    color: #265078;
    text-align: center;
    margin-bottom: 40px;
}

.blog__body__des {
    font-size: 20px;
    color: #265078;
    text-align: justify;
    margin-bottom: 40px;
}

.blog__body__image {
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
}

.blog__exm {
    font-size: 25px;
    color: #2D5F5C;
    font-weight: 600;
    text-align: left;
}

.blog__exm__tit {
    font-size: 20px;
    font-weight: 500;
    color: #265078;
    text-align: left;
}

@media only screen and (max-width: 800px) {
    .blog__body__tit {
        font-size: 35px;
    }
    .blog__body {
        padding: 60px 08vw;
    }

    .blog__body__des {
        font-size: 18px;
    }
    .blog__exm {
        font-size: 22px;
    }
    .blog__exm__tit {
        font-size: 18px;
    }
}

@media only screen and (max-width: 400px) {
    .blog__body__tit {
        font-size: 30px;
    }

    .blog__body__des {
        font-size: 16px;
    }
    .blog__exm {
        font-size: 20px;
    }
    .blog__exm__tit {
        font-size: 16px;
    }

    .blog__body {
        padding: 50px 05vw;
    }

    .blog__head {
        font-size: 28px;
    }
}