.faq {
    display: flex;
    flex-direction: column;
    background-color:#fff;
    padding: 0px 12vw;
    padding-top: 100px;
    padding-bottom: 20px;
}

.faq__tit {
    text-align: center;
    font-size: 25px;
    color: #2D5F5C;
}

.faq__titque {
    text-align: center;
    font-size: 30px;
    color: #4A4B69;
    font-weight: 700;
    margin-bottom: 50px;
}

.faq__titque__span {
    color:#2D5F5C;
    font-size: 35px;
}

.faq__section {
    cursor: pointer;
    background-color: #EDF2F7;
    padding: 25px 35px;
    border-radius: 0.5rem;
    margin-bottom: 25px;
}

.faq__section_que {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #265078;
    font-weight: 700;
}

.faq__section_ans {
    text-align: justify;
    margin-top: 15px;
    font-size: 16px;
    color: #265078;
}

.faqscreen__head {
    padding-bottom: 35px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    margin: 0px 06vw;
}

@media only screen and (max-width: 800px) {
    .faq {        
        padding: 0px 05vw;
        padding-top: 80px;
        padding-bottom: 10px;
    }

    .faq__tit {
        font-size: 22px;
    }

    .faq__titque {
        font-size: 25px;
    }

    .faq__titque__span {
        font-size: 30px;
    }

    .faq__section_que {
        font-size: 17px;
    }

    .faq__section_ans {
        font-size: 14px;
    }
}

@media only screen and (max-width: 360px) {
    .faq__section_que {
        font-size: 15px;
    }

    .faq__section_ans {
        font-size: 13px;
    }

    .faq__titque {
        font-size: 22px;
    }

    .faq__titque__span {
        font-size: 27px;
    }
}