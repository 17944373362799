.contact__head{
    padding-bottom: 35px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    margin: 0px 06vw;
}

.contact__dataform {
    padding: 100px 08vw;
    background-color: #f9f9f9;
}

.contact__dataform__data {
    display: flex;
    gap: 20px;
    align-items: center;
}

.contact__dataform__data__address {
    flex: 1;
}

.contact__dataform__data__address__tit {
    font-size: 35px;
    color: #265078;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact__dataform__data__address__indicator {
    font-size: 20px;
    color: #265078;
    font-weight: 500;
}

.contact__dataform__data__address__val {
    font-size: 16px;
    color: #265078;
}

.contact__dataform__data__img {
    flex: 1;
}

.contact__dataform__data__img__ima {    
    width: 100%;
    object-fit: contain;
}

.contact__sloat__calander {
    margin: 20px 20vw;
}

.contact__bookacall__tit {
    font-size: 35px;
    font-weight: 700;
    color: #265078;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
}

.contact__us__sides {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 50vw;
    height: 100vh;
    z-index: 100;
    transition: 850ms;
    box-shadow: -10px 0px 10px 1px #aaaaaa;
}

.contact__us__sides__close {
    cursor: pointer;
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 10px;
    color: red;    
    transform: scale(1.2);
}

.contact__us__sides__date {
    font-weight: 700;
    font-size: 20px;
    color: #2D5F5C;
    text-align: center;
    margin-bottom: 10px;
}

.contact__us__sides__timeslot {
    cursor: pointer;
    margin: 20px 100px;
    text-align: center;
    font-size: 17px;
    padding: 10px 0px;
    color: #265078;
    font-weight: 600;
    border: 1.5px solid #265078;
    border-radius: 10px;
}

.contact__us__slide__mar {
    margin: 10px 100px;
}

.contact__us__slide__input {
    width: 100%;
    padding: 12px 12px;
    font-size: 16px;
    border: 1px solid #265078;
    border-radius: 8px;
    color: #265078;
}

.contact__us__slide__input:focus {
    box-sizing: border-box;
    border: 1px solid #265078;
}

.contact__us__sides__but {
    cursor: pointer;
    margin-top: 20px;
    background-color: #2D5F5C;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding: 10px 50px;
    border-radius: 20px 50px 20px;
}

.contact__us__sides__but:hover {
    background-color: #265078;
}

.assoonasposible {
    background-color: #f9f9f9;
    padding: 0px 05vw;
    padding-top: 30px;
    text-align: center;
    font-size: 19px;
    color: #265078;
}

.errorhandlesuccess {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
    .contact__us__sides__timeslot {
        margin: 20px 80px;
    }

    .contact__us__slide__mar {
        margin: 10px 80px;
    }
}

@media only screen and (max-width: 1000px) {
    .contact__us__sides__timeslot {
        margin: 20px 60px;
    }

    .contact__us__slide__mar {
        margin: 10px 60px;
    }
}

@media only screen and (max-width: 800px) {
    .contact__dataform__data {
        flex-direction: column;
    }

    .contact__sloat__calander {
        margin: 20px 05vw;
    }

    .contact__us__sides {
        width: 80vw;
    }

    .contact__us__sides__timeslot {
        margin: 20px 50px;
    }

    .contact__us__slide__mar {
        margin: 10px 50px;
    }
}


@media only screen and (max-width: 650px) {
    .contact__us__sides {
        width: 80vw;
    }

    .contact__us__sides__timeslot {
        margin: 20px 40px;
    }

    .contact__us__slide__mar {
        margin: 10px 40px;
    }
}

@media only screen and (max-width: 500px) {
    .contact__sloat__calander {
        margin: 20px 0px;
    }

    .contact__us__sides {
        width: 95vw;
    }

    .contact__us__sides__timeslot {
        margin: 20px 35px;
    }

    .contact__us__slide__mar {
        margin: 10px 35px;
    }
}
@media only screen and (max-width: 300px) {
    .contact__head {
        font-size: 35px;
    }

    .contact__us__sides {
        width: 100vw;
    }

    .contact__us__sides__timeslot {
        margin: 20px 25px;
    }

    .contact__us__slide__mar {
        margin: 10px 25px;
    }
}