.testimonial__head{
    padding-bottom: 35px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    margin: 0px 06vw;
}

.testinomial__container {
    padding: 40px 08vw;
    background-color: #f9f9f9;
}

.testinomial__container__client {
    display: flex;
    gap: 20px;
    align-items: center;
}

.testinomial__container__client__img {
    flex: 1;
}

.testinomial__container__client__img__image {
    border-radius: 50%;
    width: 200px;
    object-fit: contain;
    margin-bottom: 20px;
}

.testinomial__container__client__img__name {
    font-size: 35px;
    font-weight: 600;
    color: #2D5F5C;
    text-align: center;
}

.testinomial__container__client__img__posi {
    font-size: 20px;
    color: #265078;
    text-align: center;
}

.testinomial__container__client__review {
    flex: 1;
    background-color: #fff;
    border-radius: 50px 10px;
    padding: 40px 0px;
    box-shadow: 0 -20px 15px -10px #265078 inset,
                0 20px 15px -10px #265078 inset,
                0 0 10px rgba(0, 0, 0, 0.1);
}

.testinomial__container__client__review__text {
    cursor: pointer;
    font-size: 16px;
    margin: 0px 20px;
    color: #265078;
    text-align: justify;
}

.testinomial__container__story {
    display: flex;
    justify-content: space-between;
}

.testinomial__container__story__but{    
    margin-top: 30px;
    padding: 12px 50px;
    background-color: #2D5F5C;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    border-radius: 20px 50px 20px;
}

.testinomial__container__story__but:hover {    
    padding-top: 6px;
    padding-bottom: 18px;
}

.testimonials__storysection {  
    flex-direction: column;
    background-color: #fff;
    padding: 40px 08vw;
}

.testimonials__storysection__tit {
    font-size: 25px;
    font-weight: 600;
    color: #2D5F5C;
    text-align: left;
    margin-bottom: 20px;
}

.testimonials__storysection__text1 {
    font-size: 16px;
    color: #265078;
    text-align: justify;
    margin-bottom: 10px;
}

.testimonials__storysection__text1__span1 {
    font-size: 20px;
    font-weight: 600;
    color: #2D5F5C;
}

.testimonials__storysection__text1__span2{    
    font-size: 18px;
    color: #265078;
    font-weight: 600;
}

.testimonials__storysection__text2 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #265078;
    margin: 20px 0px;
}

.testi__home__tit {
    font-size: 32px;
    font-weight: 700;
    color: #265078;
    text-align: center;
}

.testi__home__subtit {
    font-size: 18px;
    color: #265078;
    margin-bottom: 18px;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .testinomial__container__client {
        flex-direction: column;
    }
}

@media only screen and (max-width: 350px) {
    .testinomial__container__client__img__name {
        font-size: 30px;
    }

    .testinomial__container__client__img__posi {
        font-size: 16px;
    }

    .testinomial__container__client__review__text {
        font-size: 14px;
    }
}