.blog__head{
    padding-bottom: 35px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    margin: 0px 06vw;
}

.blog__letsfun {
    padding: 100px 05vw;
    background-color: #f9f9f9;
}

.blog__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.blog__list__blo {
    width: 450px;
    background-color: #fff;
    box-shadow: 5px 20px 20px 0px #888888;
    padding-bottom: 10px;
}

.blog__list__blo__img__tag {
    width: 100%;
    object-fit: contain;
}

.blog__list__blo__tit {
    font-size: 20px;
    font-weight: 500;
    color: #265078;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .blog__list__blo {
        width: 400px;
    }
}

@media only screen and (max-width: 450px) {
    .blog__list__blo {
        width: 350px;
    }
}

@media only screen and (max-width: 400px) {
    .blog__list__blo {
        width: 330px;
    }
    .blog__list__blo__tit {
        font-size: 17px;
    }
}

@media only screen and (max-width: 350px) {
    .blog__list__blo {
        width: 280px;
    }
}

@media only screen and (max-width: 300px) {
    .blog__list__blo {
        width: 250px;
    }
    .blog__list__blo__tit {
        font-size: 15px;
    }
}